/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 19-11-2020.
 */
import React from 'react'

import './terms.scss'

import SEO from '../components/seo'
import Layout from '../components/layout'
import { withBreakpoints } from 'react-breakpoints'

const terms = (props) => {
  return (
    <Layout>
      <SEO title="Studies&me" />

      <div className="container terms ">
        <div className="row" style={{}}>
          <div className="col-10 col-md-8 mx-auto mt-6">
            <h2 className="mb-6 mt-6">
              <b>Privacy Policy</b>
            </h2>

            <h4>1. Introduction</h4>

            <p>
              When you sign up for a clinical study via Studies&Me’s study specific landing page, and complete the
              associated online pre-screening questionnaire, Studies&Me will process different categories of sensitive
              personal data about you. You can read more specifically about the different categories of personal data
              processed and the purpose(s) for such processing in the section below. Studies&Me A/S, business
              registration no.: 41600020, Hauser Pl. 20, 1. floor, DK-1127, Copenhagen K ("Studies & Me") is the data
              controller for the processing of your personal data.{' '}
            </p>

            <h4>2. Categories of personal data, purposes and legal bases for processing</h4>

            <p>Studies & Me will process your personal data as described below.</p>

            <p>
              <b>2.1.1</b> We process your personal data when you sign up via our study specific landing page
            </p>
            <p>
              <b>2.1.2</b> The personal data processed entails ordinary categories of personal data, including your full
              name, email address, country of residence and zip-code.
            </p>

            <p>
              <b>2.1.3</b> The legal basis for the processing of your ordinary categories of personal data is article
              6(1)(f) of the General Data Protection Regulation ("GDPR"), as we have a legitimate business interest in
              contacting potential participants that have shown a specific interest in the study in question.{' '}
            </p>

            <p>
              <b>2.2.1.</b> We process personal data about you for direct marketing purposes, including for the purpose
              of contacting you about other relevant clinical studies which you may be eligible for, or in relation to
              follow-up inquiries. We will obtain you marketing consent pursuant to the Danish Marketing Practices Act.
            </p>

            <p>
              <b>2.2.2.</b> The personal data processed entails ordinary categories of personal data, including your
              name and your email address as well as the studies that may be relevant for you. We may also choose to
              target communications, such as advertisements on social media or similar platforms, subject to the
              applicable marketing laws, by use of information that may place the recipients in certain broader
              categories, such as geographic area, age, gender or interests. This information may be processed in
              relation to both the data subjects already registered in our database and the so-called "lookalikes" that
              in certain aspects may be similar to the existing data subjects and therefore may potentially be relevant
              for our commercial activities. In case previous targeting and segmentation has not been successful due to
              the chosen criteria, such criteria may also be used by Studies&Me to disregard a group of potential
              recipients and thus not target the advertising towards such recipients. The targeted advertisements will
              solely invite the potential recipients to fill out an initial questionnaire to show their interest in a
              study and hence would not automatically determine whether someone is eligible or not.
            </p>
            <p>
              <b>2.2.3.</b> The legal basis for the processing of the ordinary categories of personal data for direct
              marketing purposes is article 6(1)(f) of the GDPR, as we have a legitimate business interest in finding
              the most eligible participants to the different studies.
            </p>
            <p>
              <b>2.3.1.</b> Once you have signed up with your contact information on the study landing page, we will
              display a questionnaire, which you must fill-out. The purpose of processing your personal data in the
              questionnaire is to assess whether you may be eligible to participate in the study. Furthermore, we store
              such personal data about you for the purpose of contacting you regarding other studies that you may be
              eligible for. We also process the personal data which we receive from the study clinic. Please refer to
              the below regarding sources.
            </p>
            <p>
              <b>2.3.2.</b> The personal data processed entails ordinary categories of personal data, including full
              name, email and telephone number. The personal data you provide as answers in the questionnaire include
              special categories of personal data, including health data.
            </p>
            <p>
              <b>2.3.3.</b> The legal basis for the processing of your ordinary personal data is article 6(1)(f) of the
              GDPR, as we have a legitimate business interest in finding the most eligible participants to the different
              studies. The legal basis for the processing of special categories of personal data about you, including
              your health data is your explicit consent, cf. article 9(2)(a) of the GDPR. We will obtain your prior and
              explicit consent in connection with the questionnaire.{' '}
            </p>
            <h4>3. Sources</h4>
            <p>
              We process the personal data that you provide to us through our landing page and in the pre-screening
              questionnaire. Further, we process personal data about you that we receive from the study site. Such
              personal data can be whether you have participated in the study in question. If not, in certain cases, the
              reason for why you have not participated. The purpose is to assess whether we have recruited the right
              people to the specific study in question and whether you may be eligible for other studies in the future.
              The legal basis for the processing of such personal data will be you prior explicit consent, cf. article
              6(1)(a) of the GDPR and article 9(2)(a) of the GDPR.
            </p>
            <h4>4. Disclosure of personal data</h4>
            <p>
              Studies&Me does not disclose any of your special categories of personal data to any third parties without
              your explicit consent. If we assess that you may be eligible to participate in the specific study you are
              interested in, we will disclose your name, telephone number and email address to the study site in
              question that will contact you with regards to the next phases of the study. Targeted advertising on
              social media or other platforms may - depending on the further circumstances - entail that Studies&Me, by
              using the offered tools for targeted advertising, discloses personal data to and becomes a joint
              controller together with the social media platform vendor in question. For the time being, this is the
              case in relation to Meta Platforms, Inc. Please see the privacy policy of Meta Platforms, Inc. for further
              information: <a href="https://www.facebook.com/privacy/policy">https://www.facebook.com/privacy/policy</a>
            </p>
            <h4>5. Transfer of personal data to data processors</h4>

            <p>We will use data processors (e.g., IT platform providers) to process your personal data. </p>

            <h4>6. Transfer of personal data to recipients in third countries</h4>

            <p>
              As mentioned above, we disclose your name, email address and telephone number to the study site. Depending
              on the specific study in question, such study site may be established in a country outside of the EU/EEA,
              including in countries that have not been granted an adequacy decision from the EU Commission (see here:
              Adequacy decisions | European Commission (europa.eu)), e.g. the United States. If limited personal data is
              transferred to a country outside of EU/EEA, we will enter into EU Commission's Standard Contractual
              Clauses (see copy here: Standard Contractual Clauses (SCC) ) and ensure that sufficient safeguards and
              measures are implemented to protect your personal data.{' '}
            </p>

            <h4>7. Session-Replay Tools</h4>

            <p>
              This website uses Mouseflow: a website analytics tool that provides session replay, heatmaps, funnels,
              form analytics, feedback campaigns, and similar features/functionality. Mouseflow may record your clicks,
              mouse movements, scrolling, form fills (keystrokes) in non-excluded fields, pages visited and content on
              our website, time on site, browser, operating system, device type (desktop/tablet/phone), screen
              resolution, visitor type (first time/returning), referrer, anonymized IP address, location (city/country),
              language, and similar meta data. Mouseflow does not collect any information on pages where it is not
              installed, nor does it track or collect information outside your web browser. If you'd like to opt-out,
              you can do so by not accepting our marketing cookies and at https://mouseflow.com/opt-out. If you'd like
              to obtain a copy of your data, make a correction, or have it erased, please contact us first on
              privacy@studiesandme.com or, as a secondary option, contact Mouseflow at privacy@mouseflow.com.
              <br />
              For more information, see Mouseflow’s Privacy Policy at http://mouseflow.com/privacy/.
              <br />
              For more information on Mouseflow and GDPR, visit https://mouseflow.com/gdpr/.
              <br />
              For more information on Mouseflow and CCPA visit https://mouseflow.com/ccpa.
            </p>

            <h4>8. Storage period</h4>

            <p>
              We will only store your personal data for as long as it is deemed necessary to fulfill the above-mentioned
              purposes for the processing of your personal data. More specifically, we will store your personal data for
              5 years after we have received it.
            </p>

            <h4>9. Your rights </h4>
            <p>
              Pursuant to the GDPR, you have the below-listed data subject rights. However, please note that your data
              subject rights may be restricted.
            </p>
            <ul>
              <li>The right of access to personal data</li>
              <li>The right to rectification of inaccurate and inadequate personal data</li>
              <li>The right to erasure of personal data</li>
              <li>The right to restriction of processing of personal data</li>
              <li>The right to object</li>
              <li>The right to data portability</li>
            </ul>

            <p>
              You also have a right to submit a complaint to the competent supervisory authority, which in Denmark is
              the Danish Data Protection Agency. The Danish Data Protection Agency's contact information is available on
              their website <a href="http://www.datatilsynet.dk">www.datatilsynet.dk</a> . You can also read more about
              your data subject rights on the Danish Data Protection Agency's website here (only available in Danish).
            </p>

            <h4>10. Contact</h4>

            <p>
              If you have any questions and/or comments to the above and/or you wish to exercise your data subject
              rights, please contact us on{' '}
              <a className="border-bottom" href="mailto:privacy@studiesandme.com">
                privacy@studiesandme.com
              </a>
              .
            </p>
          </div>
        </div>
        <div className="row" style={{}}>
          <div className="col-12" style={{ height: '5rem' }}></div>
        </div>
      </div>
    </Layout>
  )
}

export default withBreakpoints(terms)
